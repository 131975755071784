import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout page="bio" route="bio">
    <Seo
      title="Biography"
      description={"About Alistair Alistair Bancroft, a Senior Front End Lead based in London currently working for John Lewis."}
    />
    <section data-background="orange" className="text-white">
      <div className="py-28 md:py-48 px-5 md:px-[2.25vw] lg:px-[3vw]">
        <h1 className="font-thin font-sans leading-snug mb-12 text-[44px] sm:text-[6.875vw] md:text-[5.72916666667vw] lg:text-[4.296875vw] xl:text-[3.4375vw] text-white">Hello, I'm Alistair Bancroft a Senior Engineering Lead based in London currently working for John Lewis Partnership.</h1>
        <h2 className="font-thin font-sans leading-snug mb-24 text-[22px] sm:text-[3.4375vw] md:text-[2.86458333333vw] lg:text-[2.1484375vw] xl:text-[1.71875vw] text-white">With a strong educational background, including a Masters degree, I have amassed over 15 years of experience in the field of front end development, specialising in various areas such as static site generation, modular and reusable code development, user-centric design, and a component-driven approach.</h2>
        <p className="font-black font-sans text-[25vw] leading-[22vw] uppercase">15<br />years<br /> of f/e</p>
      </div>
    </section>
    <section data-background="ltOrange" className="text-black">
      <div className="md:flex md:justify-end py-28 md:py-48 px-5 lg:px-10">
          <div className="font-light mb-10 tracking-wide w-full md:w-10/12">
            <p className="font-thin font-sans leading-relaxed mb-6 md:mb-8 text-[16px] sm:text-[2.5vw] md:text-[2.08333333333vw] lg:text-[1.5625vw] xl:text-[1.25vw]">I thrive in collaborative teams, where I leverage my expertise to create effective and scalable solutions. One of my standout abilities is efficient context-switching, allowing me to seamlessly transition between projects while maintaining a high level of productivity. As a creative strategist and conceptual thinker, I take pride in consistently delivering innovative and impactful solutions. My commitment to excellence is evident in my ability to produce high-quality outcomes and my keen eye for design.</p>
            <p className="font-thin font-sans leading-relaxed mb-6 md:mb-8 text-[16px] sm:text-[2.5vw] md:text-[2.08333333333vw] lg:text-[1.5625vw] xl:text-[1.25vw]">I've had the pleasure of working with many prestigious clients such as Arsenal Football Club, Aston Martin F1, and Zurich, among others. Throughout my career, I've received recognition for my work, including awards such as the Gold Pencil in The Black Plaque Project 2021 One Show Spatial Design / Wayfinding.  Currently, I am contributing my expertise at John Lewis Partnership as a Senior/Lead Front End Engineer. Prior to this, I worked at Inviqa (a Havas company), where I played crucial roles in leading and delivering various client projects.</p>
            <p className="font-thin font-sans leading-relaxed mb-6 md:mb-8 text-[16px] sm:text-[2.5vw] md:text-[2.08333333333vw] lg:text-[1.5625vw] xl:text-[1.25vw]">Apart from my professional endeavors, I'm an avid traveler with experiences in over 52+ countries. I find joy in exploring diverse cultures and learning from my journeys. Additionally, I am passionate about running and have participated in half and full marathons across the UK and Europe, as well as completing numerous Olympic and sprint triathlons, reflecting my determination to achieve ambitious goals both personally and professionally.</p>
            
            {/* <div>
              <div>
                <div className="font-light font-mono mb-2 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw] text-black">Say Hello</div>
                <p className="font-thin font-sans leading-snug mb-6 md:mb-8 md:leading-relaxed text-[16px] sm:text-[2.5vw] md:text-[2.08333333333vw] lg:text-[1.5625vw] xl:text-[1.25vw]">I am always open to collaboration whenever my schedule permits. I would be delighted to connect and explore potential opportunities together. Please feel free to reach out and get in touch.</p>
                <p className="font-thin font-sans leading-snug mb-6 md:mb-8 md:leading-relaxed text-[16px] sm:text-[2.5vw] md:text-[2.08333333333vw] lg:text-[1.5625vw] xl:text-[1.25vw]">Let's get in touch!</p>
              </div>
            </div>
            <a className="duration-300 font-black font-serif inline-block leading-tight md:leading-tight my-8 md:my-12 text-3xl md:text-5xl hover:text-primary-orange hover:underline transition" href="mailto:hello@0.7734.co.uk">hello@0.7734.co.uk</a>
              <nav>
                <ul className="flex gap-4">
                  <li className="">
                    <a aria-label="Alistair's LinkedIn Account" className="rounded-button bg-black flex h-10 items-center justify-center overflow-hidden px-5 rounded-full text-white text-sm duration-300 transition" href="https://www.linkedin.com/in/07734/" target="_blank">
                      <span>LinkedIn</span>
                      <svg className="fill-current h-5 ml-2 w-5" role="img" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><g><path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path></g></svg>
                    </a>
                  </li>
                  <li className="">
                    <a aria-label="Alistair's Twitter Account" className="rounded-button bg-black flex h-10 items-center justify-center overflow-hidden px-5 rounded-full text-white text-sm duration-300 transition" href="https://twitter.com/0_7734" target="_blank">
                      <span>@0_7734</span>
                      <svg className="fill-current h-5 ml-2 w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path></svg>
                    </a>
                  </li>
                </ul>
              </nav> */}
          {/* <div className="w-full xl:w-3/12">
            <div>
              <h3 className="font-medium mb-4 text-primary-orange text-[14px] sm:text-[2.1875vw] md:text-[1.82291666667vw] lg:text-[1.3671875vw] xl:text-[1.09375vw]">Skills and expertise /</h3>
              <h4 className="font-medium mb-1 text-[12px] sm:text-[1.875vw] md:text-[1.5625vw] lg:text-[1.171875vw] xl:text-[0.9375vw]">Web development</h4>
              <p className="font-light mb-4 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]">HTML | CSS | TailwindCSS | Sass/LESS | JS | React | NextJS | Gatsby | UX | UI | Accessibility | Contentful | Netlify | Drupal | GSAP | D3 | Node | Webpack</p>
              <h4 className="font-medium  mb-1 text-[12px] sm:text-[1.875vw] md:text-[1.5625vw] lg:text-[1.171875vw] xl:text-[0.9375vw]">Approach</h4>
              <p className="font-light mb-4 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]">Modular and reusable code | User-centric development | Component-driven approach | Collaborative teams | Efficient context-switching | Creative strategist | Conceptual thinker</p>
              <h4 className="font-medium mb-1 text-[12px] sm:text-[1.875vw] md:text-[1.5625vw] lg:text-[1.171875vw] xl:text-[0.9375vw]">Leadership</h4>
              <p className="font-light mb-6 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]">Team builder | Line manager | Mentor | Recruiter</p>
              
              <h3 className="font-medium mb-4 text-primary-orange text-[14px] sm:text-[2.1875vw] md:text-[1.82291666667vw] lg:text-[1.3671875vw] xl:text-[1.09375vw]">Awards and Recognitions /</h3>
              <p className="font-light mb-1 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]"><strong className="font-medium">Gold Pencil</strong> - The Black Plaque Project 2021 One Show Spatial Design / Wayfinding</p>
              <p className="font-light mb-1 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]"><strong className="font-medium">Graphite Pencil</strong> - The Black Plaque Project 2021 D&AD Interactive Poster Adverts</p>
              <p className="font-light mb-1 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]"><strong className="font-medium">Silver</strong> - Long Live the Local, BBA 2019 Effies UK</p>
              <p className="font-light mb-1 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]"><strong className="font-medium">Winner</strong> - Long Live the Local, BBA 2019 Campaign Media Awards</p>
              <p className="font-light mb-6 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]"><strong className="font-medium">Winner</strong> - Redrow.tv 2010 Housebuilder Awards Marketing Initiative</p>

              <h3 className="font-medium mb-4 text-primary-orange text-[14px] sm:text-[2.1875vw] md:text-[1.82291666667vw] lg:text-[1.3671875vw] xl:text-[1.09375vw]">Key Clients /</h3>
              <p className="font-light text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]">Arsenal Football Club | Aston Martin F1 | Carmignac Gestion S.A | Compare the Market | DAF Trucks | Dubai World Expo 2020 | EE | Furness Building Society | JLL | LSM | Maersk | McLaren Racing | Nestle Purina | Phonak | Ralph Lauren | Redrow Homes | Schar | Tata Group | TGI Fridays | Zurich</p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
